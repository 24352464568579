const translations = {
  translations: {
    common: {
      changeLanguage: 'Change language',
      continue: 'CONTINUE',
      finish: 'FINISH',
      select: 'Select...',
      error: 'Oops! something went wrong',
      reservation: 'Reservation',
    },
    home: {
      title: 'Dear guest, save time and do your advance check-in right now.',
      caption: 'Fill in your information and enjoy your stay with us.',
    },
    identification: {
      title: '1. Identification',
      caption: 'Inform the document of the reservation holder.',
      documentType: 'Document type',
      document: 'Document number',
      errors: {
        documentType: {
          required: 'Document type is a required field',
        },
        document: {
          required: 'Document number is a required field',
          isInvalid: 'Document number is invalid',
        },
      },
    },
    personalInformation: {
      title: '2. Personal information',
      caption: 'Fill in the details of the reservation holder',
      name: 'Name',
      lastName: 'Last name',
      birthDate: 'Birth date',
      email: 'Email',
      phoneNumber: 'Mobile',
      genre: 'Genre',
      nationality: 'Nationality',
      occupation: 'Profession',
      reason: 'Reason for travel',
      reasonLeisure: 'Leisure',
      reasonBusiness: 'Business',
      reasonOther: 'Other',
      errors: {
        name: {
          required: 'Name is a required field',
        },
        lastName: {
          required: 'Last name is a required field',
        },
        birthDate: {
          required: 'Birth date is a required field',
          isInvalid: 'Birth date is invalid',
        },
        email: {
          required: 'Email is a required field',
          isInvalid: 'Email is invalid',
        },
        phoneNumber: {
          required: 'Mobile is a required field',
          isInvalid: 'Mobile is invalid',
        },
        gender: {
          required: 'Gender is a required field',
        },
        occupation: {
          required: 'Profession is a required field',
        },
      },
    },
    emailConfirmation: {
      title: '3. Email confirmation',
      caption: 'Insert the confirmation code sent to you by email.',
      text: "Didn't receive confirmation? {{click}} to send a new code to the email {{customer_email}}.",
      click: 'Click here',
    },
    additionalInformation: {
      title: '4. Additional Information',
      caption: '',
      country: 'Country',
      zipcode: 'Zip code',
      uf: 'State',
      city: 'City',
      neighborhood: 'Neighborhood',
      street: 'Street',
      number: 'Number',
      complement: 'Complement',
      errors: {
        country: {
          required: 'Country is a required field',
        },
        zipcode: {
          required: 'Zip code is a required field',
        },
        uf: {
          required: 'State is a required field',
        },
        city: {
          required: 'City is a required field',
        },
        neighborhood: {
          required: 'Neighborhood is a required field',
        },
        street: {
          required: 'Street is a required field',
        },
        number: {
          required: 'Number is a required field',
        },
      },
    },
    companions: {
      title: '5. Companions',
      caption: 'Enter the details of the other guests.',
      captionNoFound:
        'Reservations without a companion, click below to finalize.',
      name: 'Name',
      lastName: 'Last name',
      fullName: 'Full name',
      email: 'Email',
      documentType: 'Document type',
      document: 'Document number',
      addCompanion: 'ADD A COMPANION',
      errors: {
        name: {
          required: 'Name is a required field',
        },
        lastName: {
          required: 'Last name is a required field',
        },
        fullName: {
          required: 'Full name is a required field',
        },
        email: {
          required: 'Email is a required field',
          isInvalid: 'Email is invalid',
        },
        documentType: {
          required: 'Document type is a required field',
        },
        document: {
          required: 'Document number is a required field',
          isInvalid: 'Document number is invalid',
        },
      },
    },
    success: {
      title: 'Your advance check-in was successful',
      caption:
        'Now just inform the reception desk that your advance check-in is done and enjoy your stay!',
      discoverDestinations: 'DISCOVER OUR OTHER DESTINATIONS',
      text: 'Do not worry. You will receive a confirmation email with all the information. Should you need to make any corrections you can do so at the hotel reception.',
    },
  },
};

export default translations;
