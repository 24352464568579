import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Container, ImageHeader, Text } from '../../styles';

import Button from '../../components/button';
import Card from '../../components/card';

import { ReactComponent as FlagBRSvg } from '../../assets/svg/flagbr.svg';
import { ReactComponent as FlagESSvg } from '../../assets/svg/flages.svg';
import { ReactComponent as FlagUSSvg } from '../../assets/svg/flagus.svg';
import { RootState } from '../../configStore';
import { useAppSelector } from '../../hooks';

const HomeScreen = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const changeLanguage = (lang: string) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  };

  const reservation = useAppSelector(
    (state: RootState) => state.reservation.data,
  );

  return (
    <Container>
      <br />
      <Card title="">
        {reservation.hotel && <ImageHeader src={reservation.hotel.image} />}
        <br />
        <Text size={14} weight={500} color="#000000" lineHeight={16}>
          {t('home.title')}
          <br />
          <br />
          <Text size={12} weight={400} color="#000000" lineHeight={15}>
            {t('home.caption')}
          </Text>
        </Text>
        <br />
        <Button
          backgroundColor="#EEEEEE"
          icon={<FlagBRSvg />}
          text={'INICIAR PRÉ-CHECKIN'}
          textColor="#505050"
          onClick={() => {
            changeLanguage('pt-BR');
            navigate(`identification`);
          }}
        />
        <br />
        <Button
          backgroundColor="#EEEEEE"
          icon={<FlagUSSvg />}
          text={'START PRE-CHECKIN'}
          textColor="#505050"
          onClick={() => {
            changeLanguage('en-US');
            navigate('identification');
          }}
        />
        <br />
        <Button
          backgroundColor="#EEEEEE"
          icon={<FlagESSvg />}
          text={'COMENZAR EL REGISTRO PREVIO'}
          textColor="#505050"
          onClick={() => {
            changeLanguage('es-ES');
            navigate('identification');
          }}
        />
        {/* <br />
                <Text size={12} weight={400} color="#000000" lineHeight={15}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam elit
                    pellentesque adipiscing elit amet. Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Diam elit pellentesque adipiscing elit
                    amet.
                </Text> */}
      </Card>
    </Container>
  );
};

export default HomeScreen;
