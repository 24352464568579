const translations = {
  translations: {
    common: {
      changeLanguage: 'Mudar idioma',
      continue: 'CONTINUAR',
      finish: 'FINALIZAR',
      select: 'Selecione...',
      error: 'Ops! Algo de errado aconteceu',
      reservation: 'Reserva',
    },
    home: {
      title: 'Prezado hospede, faça agora mesmo seu pré check-in',
      caption:
        'Ganhe tempo! Realize seu check-in e aproveite sua estada conosco.',
    },
    identification: {
      title: '1. Identificação',
      caption: 'Informe o documento do titular da reserva.',
      documentType: 'Tipo de documento',
      document: 'Número do documento',
      errors: {
        documentType: {
          required: 'Tipo de documento é um campo obrigatório',
        },
        document: {
          required: 'Número do documento é um campo obrigatório',
          isInvalid: 'Número do documento é inválido',
        },
      },
    },
    personalInformation: {
      title: '2. Informações Pessoais',
      caption: 'Preencha com os dados do titular da reserva',
      name: 'Nome',
      lastName: 'Sobrenome',
      birthDate: 'Data de Nascimento',
      email: 'Email',
      phoneNumber: 'Celular',
      genre: 'Gênero',
      nationality: 'Nacionalidade',
      occupation: 'Profissão',
      reason: 'Motivo da Viagem',
      reasonLeisure: 'Lazer',
      reasonBusiness: 'Negócios',
      reasonOther: 'Outro',
      errors: {
        name: {
          required: 'Nome é um campo obrigatório',
        },
        lastName: {
          required: 'Sobrenome é um campo obrigatório',
        },
        birthDate: {
          required: 'Data de Nascimento é um campo obrigatório',
          isInvalid: 'Data de Nascimento é inválido',
        },
        email: {
          required: 'Email é um campo obrigatório',
          isInvalid: 'Email é inválido',
        },
        phoneNumber: {
          required: 'Celular é um campo obrigatório',
          isInvalid: 'Celular é inválido',
        },
        gender: {
          required: 'Gênero é um campo obrigatório',
        },
        occupation: {
          required: 'Profissão é um campo obrigatório',
        },
      },
    },
    emailConfirmation: {
      title: '3. Confirmação de E-mail',
      caption: 'Informe o código de confirmação enviado por e-mail.',
      text: 'Não recebeu a confirmação? {{click}} para enviar um novo código para o email {{customer_email}}.',
      click: 'Clique aqui',
    },
    additionalInformation: {
      title: '4. Informações adicionais',
      caption: '',
      country: 'País',
      zipcode: 'CEP',
      uf: 'Estado',
      city: 'Cidade',
      neighborhood: 'Bairro',
      street: 'Endereço',
      number: 'Número',
      complement: 'Complemento',
      errors: {
        country: {
          required: 'País é um campo obrigatório',
        },
        zipcode: {
          required: 'CEP é um campo obrigatório',
        },
        uf: {
          required: 'Estado é um campo obrigatório',
        },
        city: {
          required: 'Cidade é um campo obrigatório',
        },
        neighborhood: {
          required: 'Bairro é um campo obrigatório',
        },
        street: {
          required: 'Endereço é um campo obrigatório',
        },
        number: {
          required: 'Número é um campo obrigatório',
        },
      },
    },
    companions: {
      title: '5. Acompanhantes',
      caption: 'Insira os dados dos demais hóspedes.',
      captionNoFound:
        'Reserva sem acompanhantes, clique abaixo para finalizar.',
      name: 'Nome',
      lastName: 'Sobrenome',
      fullName: 'Nome Completo',
      email: 'Email',
      documentType: 'Tipo de documento',
      document: 'Número do documento',
      addCompanion: 'ADICIONAR ACOMPANHANTE',
      errors: {
        name: {
          required: 'Nome é um campo obrigatório',
        },
        lastName: {
          required: 'Sobrenome é um campo obrigatório',
        },
        fullName: {
          required: 'Nome Completo é um campo obrigatório',
        },
        email: {
          required: 'Email é um campo obrigatório',
          isInvalid: 'Email é inválido',
        },
        documentType: {
          required: 'Tipo de documento é um campo obrigatório',
        },
        document: {
          required: 'Número do documento é um campo obrigatório',
          isInvalid: 'Número do documento é inválido',
        },
      },
    },
    success: {
      title: 'Parabéns! Seu pré check-in foi realizado com sucesso',
      caption:
        'Agora é só informar na recepção que já realizou o seu pré check-in, e aproveitar a sua hospedagem!',
      discoverDestinations: 'CONHEÇA NOSSOS OUTROS DESTINOS',
      text: 'Fique tranquilo, você receberá um e-mail de confirmação com todas as informações. Se precisar corrigir alguma informação, poderá efetuá-la na recepção do hotel.',
    },
  },
};

export default translations;
